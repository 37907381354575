@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Buhid&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  background-color: black;
  /* Set background color to black */
  color: white;
  /* Set text color to white */
}

html {
  scroll-behavior: smooth;
}

*{
  -ms-overflow-style: none;
  font-family: "Noto Sans Buhid", sans-serif !important;
}

::-webkit-scrollbar {
  display: none;
}